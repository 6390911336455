import React, {useRef, useState} from 'react';
import {Avatar, AvatarFallback, AvatarImage} from './ui/avatar';
import {Quote as QuoteIcon, Play as PlayIcon, Pause as PauseIcon } from 'lucide-react';
import {Typography} from '@/components/ui/typography';
import {
    Carousel,
    CarouselContent,
    CarouselDots,
    CarouselItem
} from "@/components/ui/carousel";
import {Card, CardContent} from "@/components/ui/card";
import { Button } from './ui/button';
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import('react-player/vimeo'), { ssr: false });
import {cn} from '@/lib/utils';

const getUserInitials = (name: string) => {
    return name.split(' ').map(it => it[0].toUpperCase()).join('');
}

type TestimonialItem = {
    video?: string;
    title: string;
    subtitle: string;
    text: string;
    image: string;
    auto_highlight?: boolean;
}

type TestimonialsSectionProps = {
    items: TestimonialItem[];
}

const TestimonialsSection = (props: TestimonialsSectionProps) => {
    const [activeVideos, setActiveVideos] = React.useState<{
        [key: number]: boolean;
    }>({});

    const [playingVideo, setPlayingVideo] = React.useState<number | null>(null);

    return (
        <div className="container my-20">
            <Typography className="text-center text-sm tracking-widest uppercase leading-relaxed font-bold">
                client experiences
            </Typography>
            <Typography variant="h2" className="text-center pb-8 border-0">
                Client’s feedback
            </Typography>

            <Carousel className="w-full" opts={{
                containScroll: 'trimSnaps'
            }}>
                <CarouselContent>
                    {props.items.map((it, index) => {
                        const [firstSentence, restText] = it.auto_highlight ? splitText(it.text) : ['', it.text]
                        const isActiveVideo = activeVideos[index];
                        return (
                            <CarouselItem key={it.title} className="flex md:basis-1/2 xl:basis-1/3">
                                <div className="flex p-1">
                                    <Card className="flex shadow-lg rounded-lg border-[1px] bg-white hover:bg-teal-50 border-gray-300">
                                        <CardContent className="flex flex-col justify-center p-6">
                                            <QuoteIcon className="w-10 h-10 mb-3 text-teal-200 rotate-180" strokeWidth={1} />

                                            <div className="flex flex-col gap-2 mb-12">
                                                {firstSentence ? (
                                                    <Typography variant="h3" className="text-lg">
                                                        {firstSentence}
                                                    </Typography>
                                                ) : null}

                                                <Typography variant="muted" className="text-sm">
                                                    {restText}
                                                </Typography>
                                            </div>

                                            <div className="flex items-end mt-auto gap-2">
                                                <div className="relative">
                                                    <div className="w-[140px] h-[140px] rounded">
                                                        <Avatar className={cn("w-[140px] h-[140px]", {
                                                            'hidden': isActiveVideo
                                                        })}>
                                                            <AvatarImage src={it.image} alt={it.title}/>
                                                            <AvatarFallback>{getUserInitials(it.title)}</AvatarFallback>
                                                        </Avatar>
                                                        {it.video && (
                                                            <div className={
                                                                cn("bg-muted relative overflow-hidden rounded-full border-2 border-avatarBorder w-[140px] h-[140px]", {
                                                                    'hidden': !isActiveVideo
                                                                })
                                                            }>
                                                                <Video
                                                                    video={it.video}
                                                                    playing={playingVideo === index}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {it.video && (
                                                        <Button
                                                            onClick={() => {
                                                                setPlayingVideo(playingVideo === index ? null : index);
                                                                setActiveVideos({
                                                                    ...activeVideos,
                                                                    [index]: true
                                                                });
                                                            }}
                                                            size="icon"
                                                            className="h-12 w-12 absolute top-[0px] right-[-24px]"
                                                        >
                                                            {playingVideo === index ? (
                                                                <PauseIcon className="w-6 h-6" strokeWidth={1} />
                                                            ) : (
                                                                <PlayIcon className="w-6 h-6" strokeWidth={1} />
                                                            )}
                                                        </Button>
                                                    )}
                                                </div>
                                                <div>
                                                    <p className="text-md font-medium leading-none">
                                                        {it.title}
                                                    </p>
                                                    <p className="text-xs text-muted-foreground">{it.subtitle}</p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </CarouselItem>
                        );
                    })}
                </CarouselContent>
                <CarouselDots />
            </Carousel>
        </div>
    )
}

function splitText(text: string): [string, string] {
    const match = text.match(/(.*?[.!?])\s*(.*)/s);
    if (match) {
        return [match[1], match[2]];
    }
    // if no match, return the whole text as the first sentence, and an empty string as the rest
    return [text, ""];
}

type VideoProps = {
    video: string;
    playing: boolean;
}

const Video = ({video, playing}: VideoProps) => {
    const playerRef = useRef<any>(null);
    const [, setIsReady] = useState<boolean>(false);

    return (
        <div
            style={{
                position: "relative",
                paddingTop: "100%",
                width: "280px",
            }}
        >
            <ReactPlayer
                ref={playerRef}
                url={video}
                controls={false}
                playing={playing}
                width='100%'
                height='100%'
                style={{
                    position: "absolute",
                    top: 0,
                    left: -70,
                }}
                onReady={() => setIsReady(true)}
                playbackRate={1}
                config={{
                    playerOptions: {
                        // responsive: true,
                        title: false,
                        byline: false,
                        portrait: false,
                    },
                }}
            />
        </div>
    )
}



export default TestimonialsSection;
