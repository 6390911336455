import {useSelector} from 'react-redux';
import {makeGetCaseByStatus, makeGetClosedCases} from '../features/casesSlice';
import {CaseStatuses} from '../constants';
import CaseCard from './cards/CaseCard';
import {Typography} from './ui/typography';
import React from 'react';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@/components/ui/tabs';
import {cn} from "@/lib/utils";

const scheduledCasesSelector = makeGetCaseByStatus({
    selectedStatus: [CaseStatuses.SCHEDULED, CaseStatuses.TENTATIVE],
    sortKey: 'scheduled_to'
});

type Case = {
    register_max: number;
    id: string;
    user: string;
    title: string;
    excerpt: string;
    public: boolean;
    state: CaseStatuses;
    scheduled_to: number;
    duration: number;
    comment_count: number;
    register_count: number;
    expertises: {
        id: string;
        title: string;
    }[]
}

const maxCases = 4;
export const ScheduledClassesCarouselSection = () => {
    const scheduledCases = useSelector(scheduledCasesSelector);
    const closedCases = useSelector(state => makeGetClosedCases('desc')(state));
    const freeCases = closedCases.filter((c: Case) => c.public).slice(0, maxCases);
    const popularCases = [
        ...closedCases,
        ...scheduledCases
    ].sort((a: Case, b: Case) => (b.comment_count + b.register_count) - (a.comment_count + a.register_count)).slice(0, maxCases);
    const upcomingCases = [...scheduledCases].sort((a: Case, b: Case) => a.scheduled_to - b.scheduled_to).slice(0, maxCases);

    return (
        <div className="container my-20">
            <Typography className="text-center text-sm tracking-widest uppercase leading-relaxed font-bold">
                materials
            </Typography>
            <Typography variant="h2" className="text-center pb-8 border-0">
                Live group sessions
            </Typography>

            <Tabs defaultValue="upcoming" className="w-full">
                <div className="flex items-center justify-center mb-6">
                    <TabsList className="m-auto flex">
                        <TabsTrigger value="free">Free</TabsTrigger>
                        <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
                        <TabsTrigger value="popular">Past</TabsTrigger>
                    </TabsList>
                </div>
                <TabsContent value="free">
                    <List cases={freeCases} />
                </TabsContent>
                <TabsContent value="upcoming">
                    <List cases={upcomingCases} />
                </TabsContent>

                <TabsContent value="popular">
                    <List cases={popularCases} />
                </TabsContent>
            </Tabs>
        </div>
    )
}

type UpcomingCasesProps = {
    cases: Case[];
}
const List = ({cases}: UpcomingCasesProps) => {
    return (
        <div
            className="grid grid-cols-1 lg:grid-cols-[repeat(auto-fit,_minmax(0,calc(25%_-_1rem)))] mx-auto gap-4 justify-center"
        >
            {cases.map(currentCase => {
                return (
                    <CaseCard key={currentCase.id} caseId={currentCase.id} />
                )
            })}
        </div>
    )
}
