import {prepareAnchorId} from '../util/format';
import React from 'react';
import {Typography} from '@/components/ui/typography';
import {ImagePosition, ListCta} from '@/components/ListCta';
import {cn} from '@/lib/utils';
import Image from 'next/image';

type TrainingZonesSectionItem = {
    title: string,
    text: string,
}

type TrainingZonesSectionProps = {
    items: TrainingZonesSectionItem[],
    title: string,
    anchor?: string,
    imgSrc: string,
    imgWidth: number,
    imgHeight: number,
    imagePosition: ImagePosition
}

const TrainingZonesStatic = ({
    items = [],
    title,
    anchor,
    imgSrc,
    imgWidth,
    imgHeight,
    imagePosition
}: TrainingZonesSectionProps) => {
    const anchorId = prepareAnchorId(anchor);

    return (
        <div id={anchorId} className="my-20">
            <Typography variant="h2" className="text-center pb-8 border-0">
                {title}
            </Typography>
            <div className={cn("bg-footer rounded text-footer-foreground grid lg:grid-flow-col p-6 gap-4 relative shadow-lg [&>*]:border-b-[1px] [&>*]:lg:border-b-0 [&>*:not(:last-child)]:lg:border-r-[1px] [&>*:not(:last-child)]:border-teal-300")}>
                {imagePosition === ImagePosition.Left && (
                    <div className={cn("hidden lg:flex items-center lg:col-span-3 px-4")}>
                        <div className="relative -mt-16">
                            <Image src={imgSrc} width={imgWidth} height={imgHeight} />
                        </div>
                    </div>
                )}
                {
                    items.map((it) => {
                        return (
                            <div className="px-4 col-span-12 lg:col-span-3" key={it.title}>
                                <h4 className="font-bold text-xl mb-2">
                                    {it.title}
                                </h4>
                                {it.text}
                            </div>
                        )
                    })
                }
                {imagePosition === ImagePosition.Right && (
                    <div className={cn("hidden lg:flex items-center lg:col-span-3 justify-self-end")}>
                        <div className="relative -mt-16">
                            <Image src={imgSrc} width={imgWidth} height={imgHeight} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};


export default TrainingZonesStatic;
